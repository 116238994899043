@import url("~react-quill/dist/quill.snow.css");
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";

.ql-editor {
  min-height: 500px;
}

.ql-toolbar.ql-snow {
  /* border: 1px solid #e3e5e9; */
  border: 0;
}

.ql-container.ql-snow {
  border: 1px solid #f2f2f5;
}

/* .fixed-editor {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 99;
} */
